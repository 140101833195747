import {
  Box,
  BoxProps,
  Button,
  Container,
  HStack,
  Heading,
  Img,
  List,
  ListItem,
  Stack,
  Text
} from "@chakra-ui/react";
import Contact from "../components/Contact";
import { FaBriefcase, FaHeart, FaLaptopCode, FaSchool } from "react-icons/fa";

const Resume = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Container maxW="container.lg" py={{ base: 6, lg: 12 }}>
        <Stack spacing={12} py={12}>
          <Heading as="h1">
            Consultant.
            <br /> Coach.
            <br /> Full stack developer.
          </Heading>
        </Stack>
      </Container>

      <Container maxW="container.lg" py={{ base: 12, lg: 24 }}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={8}
          justifyContent={"space-between"}
        >
          <Stack spacing={6}>
            <Heading color="primary">
              <FaLaptopCode />
            </Heading>
            <Heading as="h1">Skills</Heading>
            <Text fontSize="lg" fontWeight={"bold"}>
              Back-end Java <br />
              Front-end JS React <br />
              Agile & Scrum
            </Text>
            <HStack spacing={4}>
              <Box as="span" color="primary">
                <FaHeart />
              </Box>
              <Text fontSize="lg">
                Redesign & application architecture <br />
                Refactoring & clean code <br />
                Algorithms & analysis
              </Text>
            </HStack>
            <Text fontSize="lg" fontWeight={"bold"}>
              My strengths & values
            </Text>
            <HStack justifyContent={"space-between"}>
              <Text fontSize="lg" px={6}>
                innovatief <br />
                leergierig <br />
                empathisch <br />
                authentiek
              </Text>
              <Text fontSize="lg" px={6}>
                verbondenheid <br />
                ontrafelaar <br />
                ideeënfontein <br />
                strategisch
              </Text>
            </HStack>
          </Stack>

          <Img
            src="/bg/SB20_51.jpg"
            alt="Skills"
            objectFit="cover"
            objectPosition={"50% 100%"}
            w={{ base: "sm", md: "md" }}
          />
        </Stack>
      </Container>

      <Container maxW="container.lg" py={{ base: 12, lg: 24 }}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={8}
          justifyContent={"space-between"}
        >
          <Stack spacing={6}>
            <Heading color="primary">
              <FaBriefcase />
            </Heading>
            <Heading as="h1">Experience</Heading>
            <Text fontSize="lg" fontWeight={"bold"}>
              Intuitive Guide & Trainer
              <br />
              Business & IT Consulting
              <br />
              <Box as="span" fontWeight={"medium"}>
                Inside Out Consulting // School of Rebels
              </Box>
              <br />
              <Box as="span" bg="primary" color="white" px="3">
                {" "}
                2015 - Now
              </Box>
            </Text>
            <Text fontSize="lg">
              Focus op zelfontwikkeling, emoties, carrière (talenten) voor
              ondernemers. Fullstack developer, business consulting & start-up
              for online course creation.
            </Text>

            <Text fontSize="lg" fontWeight={"bold"}>
              Consultant & Software Engineer
              <br />
              <Box as="span" fontSize="lg" fontWeight={"medium"}>
                Delaware Consulting, Continuum Consulting, CERM
              </Box>
              <br />
              <Box as="span" bg="primary" color="white" px="3">
                {" "}
                2009-2015
              </Box>
            </Text>
            <Text fontSize="lg">
              Focus on Java/SAP technologies. (BPMN & Adobe Forms). Technical
              lead & scrum master. Full Stack Java/JS. Refactoring legacy code
              (C#)
            </Text>
          </Stack>

          <Img
            src="/bg/SB20_40.jpg"
            alt="experience"
            objectFit="cover"
            objectPosition={"50% 75%"}
            w={{ base: "sm", md: "md" }}
          />
        </Stack>
      </Container>

      <Container maxW="container.lg" py={{ base: 12, lg: 24 }}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={8}
          justifyContent={"space-between"}
        >
          <Stack spacing={6}>
            <Heading color="primary">
              <FaSchool />
            </Heading>
            <Heading as="h1">Education</Heading>
            <Text fontSize="lg" fontWeight={"bold"}>
              Soft skills & intuitive training
              <br />
              <Box as="span" bg="primary" color="white" px="3">
                {" "}
                2009-2023
              </Box>
            </Text>
            <Text fontSize="lg">
              Diverse coaching & persoonlijke groei opleidingen met focus op
              diepgang & transformatie.
            </Text>
            <List>
              <ListItem>
                Ayurvedisch levenstijlconsulente (School van Ayurveda, Gent, 1
                jaar)
              </ListItem>
              <ListItem>
                Stress Formatting practitioner (Stress Formatting Academie,
                Kortrijk, 4 jaar)
              </ListItem>
              <ListItem>
                Feng Shui Expert (FSDA Culemborg NL & Flowtastic Academy Brugge,
                2 jaar)
              </ListItem>
              <ListItem>
                Diepmenselijke begeleiding (Hilde Goris, Tielt-Winge, 1 jaar)
              </ListItem>
              <ListItem>
                Kindertolk (Present Child, Gentbrugge, 1 jaar)
              </ListItem>
            </List>

            <Text fontSize="lg" fontWeight={"bold"}>
              Universiteit Gent
              <br />
              <Box as="span" bg="primary" color="white" px="3">
                {" "}
                2004-2009
              </Box>
            </Text>
            <Text fontSize="lg">Bachelor in de Informatica</Text>
            <Text fontSize="lg">
              Master in de Ingenieurswetenschap: computerwetenschappen
            </Text>
            <Text>Eindwerk: fuzzy databases</Text>
          </Stack>

          <Img
            src="/bg/SB20_37.jpg"
            alt="education"
            objectFit="cover"
            objectPosition={"75% 50%"}
            w={{ base: "sm", md: "md" }}
          />
        </Stack>
      </Container>

      <Container maxW="container.lg" pb={{ base: 12, lg: 24 }}>
        <Button
          variant="outline"
          size={"lg"}
          colorScheme="blue"
          p={6}
          as="a"
          href="/CV2024-06-LynneV.pdf"
        >
          Download my resume
        </Button>
      </Container>

      <Contact />
    </Box>
  );
};

export default Resume;
