import {
  Box,
  BoxProps,
  Container,
  Heading,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import Contact from "../components/Contact";

const Coaching = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Container maxW="container.lg" py={{ base: 20, md: 40 }}>
        <Stack spacing={{ base: 12, lg: 20 }}>
          <Stack spacing={{ base: 8, lg: 12 }}>
            <Heading as="h1">Coaching</Heading>

            <Heading fontSize="lg">
              Career & website coaching
              <br />
              for entrepreneurs
            </Heading>
          </Stack>
          <Stack spacing={{ base: 8, lg: 12 }}>
            <Text fontSize="lg">
              I love inspiring & challenging you to see things differently. I
              use my own experience in both software & web development as
              computer engineer, and as intuitive soulseeker & entrepreneur & my
              endless quest for knowledge.
            </Text>
            <Text fontSize="lg">
              My coaching style is listening, mirroring, analysing, but more
              importantly I like to guide you softly on your path, exploring all
              options together - you & me, your mind, heart, hara & soul.
              Everything you need to know, is already inside you, waiting to be
              explored. Real change can only come from within yourself.
            </Text>
            <Text fontSize="lg">
              <b>
                Please consult my coaching website (in Dutch) for all
                information about my services & workshops -{" "}
                <Link color="blue" href="https://www.moonrebel.be">
                  www.moonrebel.be
                </Link>
              </b>{" "}
            </Text>
          </Stack>
        </Stack>
      </Container>

      {/* <Workshops /> */}

      <Contact />
    </Box>
  );
};

export default Coaching;
