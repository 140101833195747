import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Center,
  Container,
  HStack,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text
} from "@chakra-ui/react";
import Contact from "../components/Contact";
import { LogoWallGridEnterprise } from "../components/LogoWall";
import { ConsultantTestimonials } from "../components/Testimonials";

const Consulting = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Container maxW="container.lg" py={{ base: 20, md: 40 }}>
        <Stack spacing={{ base: 12, lg: 20 }}>
          <Stack spacing={{ base: 8, lg: 12 }}>
            <Heading as="h1">Consulting</Heading>

            <Heading fontSize="lg">
              Business & IT Consulting
              <br />
              Full-stack developer
            </Heading>
          </Stack>
          <Stack spacing={{ base: 8, lg: 12 }}>
            <Text fontSize="lg">
              I love consulting with entrepreneurs on all their website-related
              needs. Maybe you are looking for the best online platform for your
              coaching business? I can help you ask the right questions to
              <b> find the best one for you</b>. I can assist you in setting it
              up, and explain how to make changes yourself.
            </Text>
            <Text fontSize="lg">
              I love to work with very <b>creative & intuitive</b> exercises
              that draw you out of your comfort zone & into the sweet spot of
              our child-like play mode, because that is where all the magic
              happens!
            </Text>
            <Text fontSize="lg">
              My focus is always on <b>who you are</b> as a business, as a
              brand, as a person. And how you{" "}
              <b>connect to & reach your clients</b> in the best way. I use both
              my analytical problem-solving engineer mind to look at the
              problem, and my empath-artist way of feeling into what we are
              creating until it feels just right. Your website represents your
              business online & needs to give potential customers the same
              feeling as walking in your office. On request, I also use Feng
              Shui to make sure your office also breathes who you are and puts
              you in an empowering position to connect with your clients.
            </Text>

            <Text fontSize="lg">
              I love team work, learning from collegues, brainstorming on
              problems and the agile way of working. That's why I still love to
              work <b>freelance</b> as a developer in companies. I normally only
              take long term projects as it takes some time & energy to
              integrate and connect with the team.
            </Text>
          </Stack>
        </Stack>
      </Container>

      <Resume />

      <ConsultantTestimonials />

      <Container maxW="container.lg" py={{ base: 20, md: 40 }}>
        <Stack spacing={12}>
          <LogoWallGridEnterprise />
        </Stack>
      </Container>

      <Contact />
    </Box>
  );
};

export default Consulting;

const Resume = () => {
  return (
    <Box
      textAlign={{ base: "center", lg: "left" }}
      borderTop={"1px solid"}
      borderColor={"blue"}
    >
      <Container maxW="container.lg" py={{ base: 20, md: 40 }}>
        <Stack spacing={12}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
            <AspectRatio ratio={1}>
              <Img
                src="/bg/SB20_37.jpg"
                objectFit="cover"
                w={{ base: "xs", md: "sm" }}
              />
            </AspectRatio>

            <Stack spacing={{ base: 8, lg: 12 }} mt={6}>
              <Heading as="h1" fontSize="2xl">
                Consultant.
                <br /> Coach.
                <br /> Full stack developer.
              </Heading>

              <Text fontSize="lg">
                Want to know more about me?
                <br /> Check out my resume.
              </Text>
              <HStack justifyContent={{ base: "center", lg: "left" }}>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  size="lg"
                  as="a"
                  href="/CV2024-06-LynneV.pdf"
                >
                  View my CV
                </Button>
              </HStack>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
};
