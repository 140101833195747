// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },

  colors: {
    black: "#0D0D0D",
    creme: "#F0F0F2",
    gray: "#595652",

    light: "#BACBD9",
    dark: "#6D7E8C",

    blue: "#8AA6BF",
    yellow: "#FFDFB2",
    green: "#88C4AF",
    gold: "#A67A3A",

    primary: "#8AA6BF",
    secondary: "#FFDFB2",
  },

  fonts: {
    body: "Fira Code, sans-serif",
    heading: "Fira Code, sans-serif",
  },

  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "black",
        fontWeight: "300",
      },
      h1: {
        fontWeight: "400",
        textTransform: "uppercase",
      },
      // styles for the `a`
      a: {
        color: "primary",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },

  components: {
    Heading: {
      baseStyle: {
        fontWeight: "300",
        letterSpacing: "4px",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "400",
      },
    },
  },
});

export default theme;
