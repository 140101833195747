import { Box, Container, Heading, Link, Stack, Text } from "@chakra-ui/react";

const Contact = () => {
  return (
    <Box bg="primary" color="white" textAlign="center">
      <Container maxW="container.md" py={{ base: 20, md: 40 }}>
        <Stack spacing={{ base: 8, lg: 12 }}>
          <Heading as="h1">Contact me</Heading>
          <Stack spacing={{ base: 8, lg: 12 }}>
            <Text fontSize="xl">
              Questions, anekdotes, business proposals - or just want to
              connect? Just send me an email at <br />
              <Link
                as="a"
                color="secondary"
                href="mailto:lynne@insideout-consulting.be"
              >
                lynne@insideout-consulting.be
              </Link>
            </Text>
            <Text fontSize="md">
              Note: don't call me, leave a voicemail or text me on fb messenger.
              It's impossible for me to follow up on that.
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Contact;
