import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import Contact from "../components/Contact";

const Home = () => {
  return (
    <>
      <Box textAlign="center">
        <Container maxW="container.md" py={{ base: 20, md: 40 }}>
          <Stack spacing={{ base: 28, lg: 28 }}>
            <Stack spacing={{ base: 6, lg: 4 }}>
              <Heading as="h1">inside out consulting</Heading>

              <Heading as="h1" size="lg" color="primary">
                create from feeling
              </Heading>
            </Stack>
            <Stack spacing={{ base: 6, lg: 4 }}>
              <Heading size="md">consulting · coaching</Heading>
              <Heading size="md">for companies & entrepreneurs</Heading>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Contact />
    </>
  );
};

export default Home;
