import {
  Box,
  Flex,
  HStack,
  Icon,
  Img,
  Link,
  Stack,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { FaLink } from "react-icons/fa";

import { ImQuotesLeft } from "react-icons/im";

interface TestimonialProps {
  image: string;
  name?: string;
  role: string;
  website?: string;
  children: React.ReactNode;
}

export const Testimonial = (props: TestimonialProps) => {
  const { image, name, role, website, children } = props;
  let accentColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");

  return (
    <Stack
      as="blockquote"
      direction="row"
      spacing={{ base: "0", md: "8" }}
      flex="1"
      {...props}
    >
      <Img
        display={{ base: "none", md: "block" }}
        mt="2"
        flexShrink={0}
        src={image}
        alt={name}
        objectFit="cover"
        w={{ base: "20", md: "32" }}
        h={{ base: "20", md: "32" }}
        rounded="full"
      />
      <Flex w="full" direction="column">
        <Box mb="2">
          {/* mb="6" */}
          <Box as={ImQuotesLeft} color={accentColor} fontSize="xl" />
          <Text mt="3" fontSize="lg" maxW="38rem">
            {children}
          </Text>
          <Text mt="3" fontSize="sm" fontWeight="thin">
            {" << " + role + " >> "}
          </Text>
        </Box>
        <HStack>
          <Img
            display={{ base: "block", md: "none" }}
            flexShrink={0}
            src={image}
            alt={name}
            objectFit="cover"
            w={{ base: "12", md: "32" }}
            h={{ base: "12", md: "32" }}
            rounded="full"
          />
          <Box>
            {name ? (
              <Text
                as="cite"
                fontStyle="normal"
                fontSize="3xl"
                color={accentColor}
                className="handwritten"
              >
                {name}
              </Text>
            ) : (
              <></>
            )}
            {website ? (
              <Text color={accentColor}>
                <Icon as={FaLink} />{" "}
                <Link href={"https://" + website}>{website}</Link>
              </Text>
            ) : (
              <></>
            )}
          </Box>
        </HStack>
      </Flex>
    </Stack>
  );
};
