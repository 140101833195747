import {
  Box,
  BoxProps,
  Container,
  Heading,
  Img,
  Stack,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import Contact from "../components/Contact";

const About = (props: BoxProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box {...props}>
      <Container maxW="container.lg" py={{ base: 20, lg: 40 }}>
        <Stack>
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: 12, lg: 8 }}
          >
            <Stack spacing={{ base: 12, lg: 20 }}>
              <Stack spacing={{ base: 3, lg: 6 }}>
                <Heading as="h1">
                  <Box as="span" color="primary">
                    ir.
                  </Box>{" "}
                  lynne v
                </Heading>
                <Heading fontSize="lg">about me</Heading>
              </Stack>
              <Stack spacing={{ base: 8, lg: 8 }}>
                <Text fontSize="lg">
                  <b>Creating from feeling</b> is the rather rebellious concept
                  by which I stand. My passion is combining both the analytic
                  mind & really feeling that connection with all creations. The
                  analysis, algorithms and math from coding - with the creative,
                  intuitive, feeling from art & intuitive guidance.
                </Text>
                <Text fontSize="lg">
                  Creating websites is for me a way of{" "}
                  <b>expressing yourself in the world</b> and I request a lot of
                  info about who you are & why you do what you do. A website is
                  a piece of art, showing who you are, as a business, to the
                  world, the vibe's got to be just right! I'm a big fan of doing
                  things yourself, so I also love guiding customers to create a
                  website themselves using a whole bunch of creative & intuitive
                  exercises.
                </Text>
                <Text fontSize="lg">
                  As connection is one of my main strengths,{" "}
                  <b> I love working in team</b>. Coding as part of a team,
                  pairing, mobbing, brings me a lot of joy. In companies, I come
                  for the projects, but I stay for the collegues.
                </Text>
              </Stack>
            </Stack>

            {isDesktop ? (
              <Img
                src="/lynne/24_C3_05BW-zoom.jpeg"
                alt="Lynne"
                objectFit="cover"
                objectPosition={"50% 50%"}
                w={{ base: "100%", lg: "md" }}
              />
            ) : (
              <Img
                src="/lynne/24_C3_05BW-zoom.jpeg"
                alt="Lynne"
                objectFit="cover"
                objectPosition={"75% 50%"}
                w={{ base: "100%", lg: "md" }}
              />
            )}
          </Stack>
          <Heading fontSize="lg" textAlign={"right"}>
            {" "}
            ir. Lynne Vandousselaere
          </Heading>
        </Stack>
      </Container>

      <Contact />
    </Box>
  );
};

export default About;
