import { Box, SimpleGrid } from "@chakra-ui/react";
import { Testimonial } from "./Testimonial";

export const ConsultantTestimonials = () => {
  return (
    <Box as="section" bg="primary" color="white">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "12", md: "16" }}
      >
        <SimpleGrid
          py="24"
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: "8", lg: "16" }}
        >
          <Testimonial
            role="Thomas - project manager Eandis 2013"
            image="/clients/men.jpeg"
          >
            Lynne is een uitstekende ontwikkelaar die meedenkt met de business.
            Ze schat het werk goed in, en levert op tijd op. Ze durft grotere
            zaken herbekijken om zo de algemene kwaliteit op peil te houden.
            Binnen Eandis gaf ze ook advies rond andere mobile architecturen.
          </Testimonial>
          <Testimonial
            role="Gwen - oprichtster loopbaancentrum Coaching Life"
            image="/clients/gwen.jpg"
          >
            Dankjewel voor alles. Ik houd van jouw intelligentie, creativiteit
            en het feit dat je mijn business en mij echt kan zien en voelen x
          </Testimonial>
        </SimpleGrid>
      </Box>
    </Box>
  );
};
