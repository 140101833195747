import { Box, Container, Link, Text, TextProps } from "@chakra-ui/react";

export const Footer = () => (
  <Box as="footer" role="contentinfo">
    <Container maxW="container.xl">
      <Box mx="auto" maxW="5xl" py={{ base: 8, lg: 12 }}>
        <Copyright />
      </Box>
    </Container>
  </Box>
);

const Copyright = (props: TextProps) => (
  <Text {...props}>
    / Inside Out / Consulting, Oud-Strijderslaan 26, 8200 Sint-Andries
    {" - "}
    by ir. Lynne Vandousselaere
    {" - "}
    <Link href="mailto:lynne@insideout-consulting.be" color="primary">
      lynne@insideout-consulting.be
    </Link>{" "}
    &copy; {new Date().getFullYear()} All rights reserved.
  </Text>
);
