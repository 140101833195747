import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  IconButton,
  Link,
  Text,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerProps,
  Stack,
  chakra,
  type IconButtonProps,
  useDisclosure,
  Img
} from "@chakra-ui/react";

import { Link as RouteLink } from "react-router-dom";

type NavLinkProps = { text: string; to: string };
const NavLink = ({ to, text }: NavLinkProps) => (
  <Link color="primary" as={RouteLink} to={to}>
    <Text fontSize="xl">{text}</Text>
  </Link>
);

export const NavBar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();

  return (
    <Box as="section">
      <Box
        boxShadow="sm"
        bg={isDesktop ? "none" : "blue"}
        position="relative"
        zIndex="tooltip"
      >
        <Container py="4">
          <HStack justify="space-between">
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup
                  size="lg"
                  variant="text"
                  colorScheme="gray"
                  spacing="8"
                >
                  <Button>
                    <NavLink to="/" text="Home" />
                  </Button>
                  <Button>
                    <NavLink to="/coaching" text="Coaching" />
                  </Button>
                  <Button>
                    <NavLink to="/consulting" text="Consulting" />
                  </Button>
                  <Button>
                    {" "}
                    <NavLink to="/about" text="About" />
                  </Button>
                </ButtonGroup>
              </HStack>
            ) : (
              <>
                <Logo />
                <ToggleButton
                  onClick={mobileNavbar.onToggle}
                  isOpen={mobileNavbar.isOpen}
                  aria-label="Open Menu"
                  color="white"
                />
                <MobileDrawer
                  isOpen={mobileNavbar.isOpen}
                  onClose={mobileNavbar.onClose}
                />
              </>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
};

const MobileDrawer = (props: Omit<DrawerProps, "children">) => (
  <Drawer placement="top" {...props}>
    <DrawerContent>
      <DrawerBody mt="16">
        <Stack spacing="6" align="stretch" pt={12}>
          <Button>
            <NavLink to="/" text="Home" />
          </Button>
          <Button>
            <NavLink to="/coaching" text="Coaching" />
          </Button>
          <Button>
            <NavLink to="/consulting" text="Consulting" />
          </Button>
          <Button>
            {" "}
            <NavLink to="/about" text="About" />
          </Button>
        </Stack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

const Logo = () => {
  // return (
  //   <Img src="/logo/io-blue.png" alt="Logo IO" width="120px" bg="primary" />
  // );
  return (
    <Img src="/logo/io-blue-small.png" alt="Logo IO" width="80px" my={4} />
  );
};

interface ToggleButtonProps extends IconButtonProps {
  isOpen: boolean;
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const { isOpen, ...iconButtonProps } = props;
  return (
    <IconButton
      variant="unstyled"
      display="inline-flex"
      size="xs"
      icon={<ToggleIcon active={isOpen} />}
      {...iconButtonProps}
    />
  );
};

const ToggleIcon = (props: { active: boolean }) => {
  const { active } = props;
  return (
    <Box
      color="fg.muted"
      className="group"
      data-active={active ? "" : undefined}
      as="span"
      display="block"
      w="1.5rem"
      h="1.5rem"
      pos="relative"
      aria-hidden
      pointerEvents="none"
    >
      <Bar
        top="0.4375rem"
        _groupActive={{ top: "0.6875rem", transform: "rotate(45deg)" }}
      />
      <Bar
        bottom="0.4375rem"
        _groupActive={{ bottom: "0.6875rem", transform: "rotate(-45deg)" }}
      />
    </Box>
  );
};

const Bar = chakra("span", {
  baseStyle: {
    display: "block",
    pos: "absolute",
    w: "1.25rem",
    h: "0.125rem",
    rounded: "full",
    bg: "currentcolor",
    mx: "auto",
    insetStart: "0.125rem",
    transition: "all 0.12s"
  }
});
