import { Box, Center, Img, Stack, useBreakpointValue } from "@chakra-ui/react";
import { NavBar } from "./Navbar";

const Header = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box as="section">
      <Stack spacing={1}>
        {isDesktop ? (
          <Center py={4}>
            <Logo />
          </Center>
        ) : (
          <></>
        )}
        <NavBar />
      </Stack>
    </Box>
  );
};

const Logo = () => {
  return (
    <Img src="/logo/io-blue.png" alt="Logo IO" width="120px" bg="primary" />
  );
  // return <Img src="/logo/full-small-light.png" alt="Logo IO" width="220px" />;
};

export default Header;
