import {
  Box,
  BoxProps,
  Heading,
  Img,
  Link,
  SimpleGrid,
  Stack
} from "@chakra-ui/react";

export const LogoWallGridEnterprise = (props: BoxProps) => {
  return (
    <>
      <Box {...props}>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={8} pb={8}>
          <Stack>
            <Heading as="h1">
              meet my{" "}
              <Box as="span" color="primary">
                clients
              </Box>
            </Heading>
            <Heading fontSize="lg">
              Proud to have been a part of their organisation, journey and
              learned from the amazing team
            </Heading>
          </Stack>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
          spacing={{ base: 24, lg: 24 }}
          py={{ base: 6, lg: 24 }}
          m={{ base: 16, sm: 8, md: 8, lg: 4 }}
        >
          <LogoLink
            name="Skryv"
            link="http://www.skryv.com"
            image="/clients/skryv.png"
          />
          <LogoLink
            name="Summa"
            link="http://www.summa.com"
            image="/clients/summa.png"
          />
          <LogoLink
            name="Coaching Life"
            link="https://www.coachinglife.be"
            image="/clients/coachinglife.png"
          />
          <LogoLink
            name="Cortina"
            link="https://www.cortina-group.com"
            image="/clients/cortina.svg"
          />
          <LogoLink
            name="Cerm"
            link="https://www.cerm.net"
            image="/clients/cerm.jpeg"
          />
          <LogoLink name="Eandis" image="/clients/eandis.jpeg" />
          <LogoLink
            name="Delaware Consulting"
            link="https://www.delaware.pro"
            image="/clients/delaware.png"
          />
        </SimpleGrid>
      </Box>
    </>
  );
};

interface LogoLinkProps {
  image: string;
  name: string;
  link?: string;
}

export const LogoLink = ({ image, name, link }: LogoLinkProps) => {
  return (
    <>
      <Link href={link} target="_blank">
        <Img
          src={image}
          alt={name}
          objectFit="contain"
          objectPosition={"77% 50%"}
          w={{ base: "sm", md: "lg" }}
          // h={{ base: "sm", md: "lg"}}
        />
      </Link>
    </>
  );
};
