import "./styles/globals.scss";
import {
  ChakraProvider,
  Container,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";
import theme from "./theme";
import Home from "./pages/Home";
import {
  createBrowserRouter,
  isRouteErrorResponse,
  RouterProvider,
  useRouteError
} from "react-router-dom";
import Coaching from "./pages/Coaching";
import Root from "./pages/Root";
import About from "./pages/About";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import Consulting from "./pages/Consulting";
import Resume from "./pages/Resume";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/coaching",
        element: <Coaching />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/consulting",
        element: <Consulting />
      },
      {
        path: "/resume",
        element: <Resume />
      }
    ]
  }
]);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <Header />
        <Container maxW="container.lg" py={24}>
          <Stack spacing={6}>
            <Heading>Oops.. </Heading>
            <Text fontSize="2xl">This is not working.</Text>
            <Text fontSize="2xl" color="primary">
              {error.status} {error.statusText}
            </Text>
            {error.data?.message && (
              <Text fontSize="xl" color="gray" fontStyle={"italic"}>
                {error.data.message}
              </Text>
            )}
          </Stack>
        </Container>
        <Footer />
      </>
    );
  } else if (error instanceof Error) {
    return (
      <>
        <Header />
        <Container maxW="container.lg" py={24}>
          <Stack spacing={6}>
            <Heading>Oops.. Unexpected Error </Heading>
            <Text fontSize="2xl" color="primary">
              Something went wrong
            </Text>
            <Text fontSize="xl" color="gray" fontStyle={"italic"}>
              {error.message}
            </Text>
          </Stack>
        </Container>
        <Footer />
      </>
    );
  } else {
    return <></>;
  }
}

export default App;
